<template>
  <div class="w750">
    <div class="logo">
      <img src="../../assets/logo.png" alt="">
    </div>
    <div class="top">
      <div class="title">需要您的授权</div>
      <div class="desc">为了给您提供更好的服务，和方便您更好的使用小程序，我们需要获取您的一些授权</div>
    </div>
    <div class="main">
      <div class="content" :class="userName ? 'contentActive' : ''">
        <div class="left">
          <div class="l_top">1.授权微信登录权限</div>
          <div class="l_bottom">请授权您的账号信息</div>
        </div>
        <div class="right" @click="handleScope(0)">授权</div>
      </div>
      <div class="content spec" :class="!userName && !userId ? 'contentActive' : ''">
        <div class="left">
          <div class="l_top">2.手机号码使用权限</div>
          <div class="l_bottom">需要您去登录，方便您进行预约使用</div>
        </div>
        <div class="right" @click="handleScope(1)">登录</div>
      </div>
    </div>
    <!--
    <div class="loginBottom">
      <van-checkbox v-model="checked" @change="handleChecked" icon-size="18px" checked-color="#1487cd"></van-checkbox>
      <div class="agree">登录即代表您已阅读并同意，<router-link :to="{ path: '/xxx' }">《用户服务协议》</router-link> </div>
    </div>-->
  </div>
</template>

<script>
import { getLocalStorage, isWeiXinScan, removeLocalStorage, setLocalStorage } from '@/utils'
import { AppId } from '@/constans/config'

export default {
  name: 'Scope',

  data () {
    return {
      headerTitle: '授权信息',
      checked: false,
      isWeiXin: false,
      wxCode: '',
      userId: '',
      userName: ''
    }
  },

  /* watch: {
    wxCode (val) {
      if (val) {
        this.handleScope(0)
      }
    }
  }, */

  mounted () {
    if (getLocalStorage('SET_AGREE_STATUS')) {
      this.checked = true
    }

    this.isWeiXin = isWeiXinScan()
    this.wxCode = this.getUrlKey('code')

    if (this.wxCode) {
      this.handleScope(0)
    }
  },

  methods: {
    /** 获取url 参数 */
    getUrlKey (name) {
      return (
        // eslint-disable-next-line no-sparse-arrays
        decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
      )
    },

    /** 获取code **/
    getCodeApi (state) {
      const urlNow = encodeURIComponent(window.location.href)
      const scope = 'snsapi_userinfo' // 静默授权 snsapi_userinfo
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
      window.location.replace(url)
    },

    handleChecked (e) {
      if (e) {
        setLocalStorage('SET_AGREE_STATUS', 1)
      } else {
        removeLocalStorage('SET_AGREE_STATUS')
      }
      this.checked = e
    },

    /** 授权 */
    handleScope (status) {
      if (!status) {
        // this.login('021nbQll2AwL9c4He0nl2eXvKm0nbQlk')
        // return
        if (this.userName) return
        if (this.isWeiXin) {
          const code = this.wxCode
          if (code) {
            this.login(code)
          } else {
            this.getCodeApi('123')
          }
        } else {
          this.$toast.clear()
          alert('请在微信中打开')
        }
      } else {
        if (!this.userName && !this.userId) return
        this.$router.replace({
          path: '/login',
          query: {
            code: this.wxCode
          }
        })
      }
    },

    login (code) {
      const newData = {
        params: { code },
        method: 'h5-mobile-social-login'
      }

      this.$toast.loading('玩命加载中...')
      this.$store.dispatch('LoginByUserName', newData).then(res => {
        this.$toast.clear()
        if (res.username) {
          this.userName = res.username
          this.userId = res.user_id || ''

          if (res.user_id) {
            this.$router.replace({
              path: '/',
              id: ''
            })
          }
        } else {
          this.$toast({
            message: res.msg,
            position: 'bottom'
          })
        }
      }, err => {
        this.$toast.clear()
        this.$toast(err.message)
      }).catch(err => {
        this.$toast.clear()
        this.$toast(err.data.message)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/mixin";
  .w750 {
    height: 100vh;
    padding: 40px 50px;
    .logo {
      @include CCC;
      padding-top: 10%;
      img {
        @include wh(300px, 408px);
      }
    }
    .top {
      padding-top: 10%;
      .title {
        @include sc(32px, #1a1a1a);
        font-weight: bold;
        margin-bottom: 20px;
      }
      .desc {
        @include sc(28px, #666);
      }
    }
    .main {
      padding-top: 20%;
      .content {
        @include RCSB;
        .left {
          padding-right: 30px;
          .l_top {
            @include sc(32px, #1a1a1a);
            margin-bottom: 10px;
          }
          .l_bottom {
            @include sc(28px, #666);
          }
        }
        .right {
          white-space: nowrap;
          height: 68px;
          padding: 0 50px;
          border-radius: 34px;
          @include RCC;
          background: $themeColor;
          @include sc(28px, #fff);
        }
        .right:active {
          background: $themeColorActive;
        }
      }
      .contentActive {
        opacity: 0.3;
      }
      .spec {
        padding-top: 50px;
      }
    }
    .loginBottom {
      @include RCC;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
      text-align: center;
      height: 118px;
      /*line-height: 98px;*/
      .agree {
        /*padding: 46px 0;*/
        line-height: 1;
        padding-left: 15px;
        @include sc(28px, #999);
      }
      a {
        @include sc(28px, $themeColor);
      }
    }
  }
</style>
